/* -- set app title --*/
const AppTitle = 'CiFI Lottery Super Admin';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://www.dev.lottery.server.cifiapp.com';
const production = 'https://www.dev.lottery.server.cifiapp.com';
const development = 'https://www.dev.lottery.server.cifiapp.com';

let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 51;
    SocketUrl = development;
    message = 'Please switch your network to XDC Testnet';
    explorer = 'https://explorer.apothem.network'
    break;
  case 'production':
    networkId = 50;
    SocketUrl = production;
    message = 'Please switch your network to XDC Mainnet';
    explorer = 'https://xdc.blocksscan.io'
    break;
  case 'testing':
    networkId = 4;
    SocketUrl = testing;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    break;
  default:
    networkId = 51;
    SocketUrl = 'http://localhost:4000';
    message = 'Please switch your network to XDC Testnet';
    explorer = 'https://explorer.apothem.network'
}
let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env };