const { web3 } = require('../web3');
const { env } = require('../config');

const NFTData = require(`./${env}/NFT.json`);
const LotteryData = require(`./${env}/Lottery.json`);
const CIFITokenData = require(`./${env}/CIFIToken.json`);
const WhitelistData = require(`./${env}/Whitelist.json`);
const SubscriptionData = require(`./${env}/Subscription.json`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const NFTABI = NFTData['abi'];
const NFTBytecode = NFTData['bytecode'];

const LotteryABI = LotteryData['abi'];
const LotteryBytecode = LotteryData['bytecode'];

const AdminWhitelistABI = WhitelistData['abi'];
const AdminWhitelistAddress = WhitelistData['address'];
const AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

const CIFITokenABI = CIFITokenData['abi'];
const CIFITokenAddress = CIFITokenData['address'];
const CIFIToken = new web3.eth.Contract(CIFITokenABI, CIFITokenAddress);

const SubscriptionABI = SubscriptionData['abi'];
const SubscriptionAddress = SubscriptionData['address'];
const Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);


module.exports = {
  NFTABI, NFTBytecode,
  LotteryABI, LotteryBytecode,
  AdminWhitelistABI ,AdminWhitelistAddress ,AdminWhitelist,
  CIFITokenABI ,CIFITokenAddress ,CIFIToken,
  SubscriptionABI ,SubscriptionAddress ,Subscription
};
