import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { validate } from "wallet-address-validator";

import "./index.css";
import { web3 } from "../../store/web3.js";
import { AdminWhitelistAddress, AdminWhitelist, SubscriptionAddress, Subscription } from "../../store/contract/index.js"
import { setLoader, kybGlobal, deleteKYB, setApprovedKYB } from "../../store/actions/Auth.js";

class KYBList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerKYBData: [],
      lotteryPrice: 0,
      lotterySubPrice: "",
    };
  }

  async componentDidMount() {
    await this.getTransactionData();
  }

  componentDidUpdate(prevprops, PrevState) {
    let { kybGData } = this.props;
    if (prevprops.kybGData !== kybGData) this.setState({ registerKYBData: kybGData });
  }

  getTransactionData = async () => {
    try {
      this.props.setLoader({
        message: "Loading Data ...",
        status: true,
      });

      let lotteryPrice = await Subscription.methods.lotterySubscriptionPrice().call();
      lotteryPrice = web3.utils.fromWei(lotteryPrice, "ether");

      this.setState({ lotteryPrice });
      await this.props.kybGlobal();
    } catch (error) {
      this.props.setLoader({ status: false });
    }
  }

  handleChange = async (e) => {
    e.preventDefault();
    if ([e.target.name] == "lotterySubPrice") {
      if (parseFloat(e.target.value) > 0) {
        this.setState({ lotterySubPrice: parseFloat(e.target.value) });
      } else {
        this.setState({ lotterySubPrice: "" });
      }
    }
  }

  approvedKybUser = async (item) => {
    try {
      let deployer = (await web3.currentProvider.enable())[0];
      let isSuperAdmin = await (AdminWhitelist.methods.isSuperAdmin(deployer).call({ from: deployer }));
      if (isSuperAdmin == true) {
        let isApprove = await (AdminWhitelist.methods.isWhitelist(item['publicAddress']).call());
        if (isApprove == true) {
          this.props.setLoader({ message: 'Approving KYB Please Wait...', status: true });
          this.props.setApprovedKYB({ _id: item['_id'] });
          this.props.setLoader({ status: false });
        } else {
          this.props.setLoader({ message: 'Approving KYB Please Wait...', status: true });
          await web3.eth
            .sendTransaction({
              from: deployer,
              value: 0,
              to: AdminWhitelistAddress,
              gas: 2000000,
              data: AdminWhitelist.methods
                .addWhitelist(item['publicAddress'])
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.props.setApprovedKYB({ _id: item['_id'] });
              this.props.setLoader({ status: false });
            });
        }
      } else {
        EventBus.publish("error", `Only super admin can approve KYB!`);
        return;
      }
    } catch (error) {
      this.props.setLoader({ status: false });
      return EventBus.publish('error', error.message);
    }
  }

  deleteKybItem = async (item) => {
    try {
      let deployer = (await web3.currentProvider.enable())[0];
      let isSuperAdmin = await (AdminWhitelist.methods.isSuperAdmin(deployer).call({ from: deployer }));
      if (isSuperAdmin == true) {
        let isApprove = await (AdminWhitelist.methods.isWhitelist(item['publicAddress']).call());
        if (isApprove == false) {
          this.props.setLoader({ message: 'Deleting KYB Please Wait...', status: true });
          this.props.deleteKYB({ _id: item['_id'] });
          this.props.setLoader({ status: false });
        } else {
          this.props.setLoader({ message: 'Deleting KYB Please Wait...', status: true });
          await web3.eth
            .sendTransaction({
              from: deployer,
              value: 0,
              to: AdminWhitelistAddress,
              gas: 2000000,
              data: AdminWhitelist.methods
                .removeWhitelist(item['publicAddress'])
                .encodeABI(),
            })
            .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
            .on("receipt", async (receipt) => {
              this.props.deleteKYB({ _id: item['_id'] });
              this.props.setLoader({ status: false });
            });
        }
      } else {
        EventBus.publish("error", `Only super admin can approve KYB!`);
        return;
      }
    } catch (error) {
      this.props.setLoader({ status: false });
      return EventBus.publish('error', error.message);
    }
  }

  updateLotterySub = async (e) => {
    try {
      let { lotterySubPrice } = this.state;

      let deployer = (await web3.currentProvider.enable())[0];

      if (lotterySubPrice == "") {
        EventBus.publish("error", `Please enter lottery subscription price`);
        return;
      }

      if (parseFloat(lotterySubPrice) <= 0) {
        EventBus.publish("error", `Please enter lottery subscription price`);
        return;
      }

      let isSuperAdmin = await (AdminWhitelist.methods.isSuperAdmin(deployer).call({ from: deployer }));
      if (isSuperAdmin == true) {
        this.props.setLoader({ message: 'Updating Lottery Subscription Price...', status: true });

        lotterySubPrice = web3.utils.toWei(lotterySubPrice.toString(), "ether");

        await web3.eth
          .sendTransaction({
            from: deployer,
            value: 0,
            to: SubscriptionAddress,
            gas: 5000000,
            data: Subscription.methods
              .setPriceLottery(lotterySubPrice)
              .encodeABI(),
          })
          .on('transactionHash', hash => console.log(`************** Tx hash = ${hash}`))
          .on('receipt', async receipt => {
            EventBus.publish("success", `Updated Lottery Subscription Price Successfully!`);
            this.getTransactionData();
            this.props.setLoader({ status: false });
            this.setState({ lotterySubPrice: "" });
          });
      } else {
        EventBus.publish("error", `Only super admin can update lottery subscription price!`);
        return;
      }
    } catch (e) {
      console.log("Error :: ", e);
      this.props.setLoader({ status: false });
      this.setState({ lotterySubPrice: "" });
      return EventBus.publish("error", "Lottery Subscription Price Update Failed");
    }
  }

  render() {
    let { registerKYBData, lotteryPrice, lotterySubPrice } = this.state;

    console.log("*** registerKYBData :: ", registerKYBData);
    console.log("*** lotteryPrice :: ", lotteryPrice);

    return (
      <>
        <div className="tab-pane fade show active" id="newKyb" role="tabpanel" aria-labelledby="newKyb-tab">
          <div className="super-admin-head">
            <h1>KYB List</h1>

            <div className="super-admin">
              <span>Super Admin</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="type">Type</th>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="businessTitle">Business Title</th>
                  <th id="businessId">Business EIN</th>
                  <th id="ownerTaxId">Onwer Tax ID</th>
                  <th id="website">Website</th>
                  <th id="facebook">Facebook</th>
                  <th id="linkedIn">LinkedIn</th>
                  <th id="twitter">Twitter</th>
                  <th id="document">Document</th>
                  <th id="delete">Delete</th>
                  <th id="approve">Approve</th>
                </tr>
              </thead>
              <tbody>
                {registerKYBData.filter(item => item.kybStatus == "submitted").map((item) =>
                  <tr>
                    {
                      item['isKYN'] && item['isKYN'] == true ? (
                        <td>CIFI Give</td>
                      ) : item['isGreenCross'] && item['isGreenCross'] == true ? (
                        <td>Green Cross</td>
                      ) : (
                        <td>CIFI</td>
                      )
                    }
                    <td>{item['kybStatus'] ? item['kybStatus'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['title'] ? item['title'] : "-"}</td>
                    <td>{item['ein'] ? item['ein'] : "-"}</td>
                    <td>{item['ownerSocialNo'] ? item['ownerSocialNo'] : "-"}</td>
                    <td>{item['website'] ? item['website'] : "-"}</td>
                    <td>{item['facebook'] ? item['facebook'] : "-"}</td>
                    <td>{item['linkedin'] ? item['linkedin'] : "-"}</td>
                    <td>{item['twitter'] ? item['twitter'] : "-"}</td>
                    <td><a target="_blank" href={item['document'] && item['document']}>{item['document'] ? item['document'].substring(0, 15) + '...' : "-"}<img src={require('../../assets/img/edit.svg')} alt='' /></a></td>
                    <td>
                      <button onClick={() => this.deleteKybItem(item)}>Delete</button>
                    </td>
                    <td>
                      {item['kybStatus'] !== "approved" ?
                        <button onClick={() => this.approvedKybUser(item)}>Approve</button>
                        :
                        item['kybStatus']
                      }
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="kybApproved" role="tabpanel" aria-labelledby="kybApproved-tab">
          <div className="super-admin-head">
            <h1>KYB Approved by Admin</h1>

            <div className="super-admin">
              <span>Super Admin</span>


            </div>
          </div>
          <div className="customer-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th id="type">Type</th>
                  <th id="status">Status</th>
                  <th id="publicAddress">Wallet Address</th>
                  <th id="businessTitle">Business Title</th>
                  <th id="businessId">Business EIN</th>
                  <th id="ownerTaxId">Onwer Tax ID</th>
                  <th id="website">Website</th>
                  <th id="facebook">Facebook</th>
                  <th id="linkedIn">LinkedIn</th>
                  <th id="twitter">Twitter</th>
                  <th id="document">Document</th>
                  <th id="delete">Delete</th>
                </tr>
              </thead>
              <tbody>
                {registerKYBData.filter(item => item.kybStatus == "approved").map((item) =>
                  <tr>
                    {
                      item['isKYN'] && item['isKYN'] == true ? (
                        <td>CIFI Give</td>
                      ) : item['isGreenCross'] && item['isGreenCross'] == true ? (
                        <td>Green Cross</td>
                      ) : (
                        <td>CIFI</td>
                      )
                    }
                    <td>{item['kybStatus'] ? item['kybStatus'] : "-"}</td>
                    <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                    <td>{item['title'] ? item['title'] : "-"}</td>
                    <td>{item['ein'] ? item['ein'] : "-"}</td>
                    <td>{item['ownerSocialNo'] ? item['ownerSocialNo'] : "-"}</td>
                    <td>{item['website'] ? item['website'] : "-"}</td>
                    <td>{item['facebook'] ? item['facebook'] : "-"}</td>
                    <td>{item['linkedin'] ? item['linkedin'] : "-"}</td>
                    <td>{item['twitter'] ? item['twitter'] : "-"}</td>
                    <td><a target="_blank" href={item['document'] && item['document']}>{item['document'] ? item['document'].substring(0, 15) + '...' : "-"}<img src={require('../../assets/img/edit.svg')} alt='' /></a></td>
                    <td>
                      <button onClick={() => this.deleteKybItem(item)}>Delete</button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
          <div className="super-admin-head">
            <h1>Update Lottery Subscription</h1>

            <div className="super-admin">
              <span>Super Admin</span>
            </div>
          </div>

          <div className="form-wrap min">
            <div className="inner">
              <div className="head">
                <h2>Lottery Subscription</h2>
              </div>
              <form onSubmit={this.updateLotterySub}>
                <div className="form-group">
                  <label>Lottery Subscription Price: {lotteryPrice} CIFI</label>
                  <input
                    type="text"
                    name='lotterySubPrice'
                    defaultValue={lotterySubPrice}
                    placeholder="Lottery Subscription Price"
                    onChange={this.handleChange}
                  />
                </div>
                <button type="submit">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  kybGlobal,
  deleteKYB,
  setApprovedKYB,
};

const mapStateToProps = ({ Auth }) => {
  let { kybGData } = Auth;
  return { kybGData };
};
export default connect(mapStateToProps, mapDispatchToProps)(KYBList);
