import React from "react";
import { connect } from 'react-redux';
import { PropTypes } from "prop-types";

import './Sidebar.css';
import { logout } from '../../store/actions/Auth';
import { Link } from "react-router-dom";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  logout = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  render() {
    const { role, bgColor, routes, rtlActive, logo } = this.props;

    return (
      <div className="sidebar-kyc" data={bgColor}>
        <div className="logo"><img src="images/logo.svg" alt="logo" />Circularity Finance</div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
         
          <li className="nav-item" role="presentation">
              <button onClick={()=>this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYBDetails' ? "nav-link active" : "nav-link "}`} id="newKyb-tab" data-toggle="tab" data-target="#newKyb" type="button" role="tab" aria-controls="newKyb" aria-selected="true">
                <img src="images/kyc-user.png" alt="" /> New KYB
              </button>
          </li>

          <li className="nav-item" role="presentation">
              <button onClick={()=>this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYBDetails' ? "nav-link" : "nav-link "}`} id="kybApproved-tab" data-toggle="tab" data-target="#kybApproved" type="button" role="tab" aria-controls="kybApproved" aria-selected="true">
                <img src="images/kyc-user.png" alt="" /> Approved KYB
              </button>
          </li>

          <li className="nav-item" role="presentation">
              <button onClick={()=>this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYBDetails' ? "nav-link" : "nav-link "}`} id="subscription-tab" data-toggle="tab" data-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="true">
                <img src="images/kyc-user.png" alt="" /> Lottery Subscription
              </button>
          </li>

          <li className="nav-item" role="presentation">
            <button className="nav-link" onClick={this.logout}><img src="images/logout.svg" alt="" /> Logout</button>
          </li>
        </ul>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

// export default Sidebar;


const mapDispatchToProps = { logout };

const mapStateToProps = ({ Auth }) => {
  let { role } = Auth;
  return { role };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);