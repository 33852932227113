import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react'
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { web3 } from "./store/web3";
import Admin from "./layouts/Admin.jsx";
import Login from "./views/Login/index.js";
import PrivateRoute from './store/PrivateRoute';
import { networkId, message } from "./store/config";
import { logout, setAddress } from './store/actions/Auth';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const hist = createBrowserHistory();

export class App extends Component {

  async componentDidMount() {
    this.handleWalletChanges();
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
  };

  handleWalletChanges = () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install xdc extension!");
      return;
    };
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on('chainChanged', this.handleEthereumChange);
      window.ethereum.on('accountsChanged', this.handleEthereumChange);
      web3.eth.net.getId((err, netId) => {
        if (netId != networkId) EventBus.publish('info', message);
      });
    }
  };

  handleEthereumChange = () => {
    this.props.logout();
    window.location.reload();
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <Router history={hist}>
          <Switch>
            <Route path="/login" render={props => <Login {...props} />} />
            <PrivateRoute path="/home" component={props => <Admin {...props} />} />
            <Redirect from="/" to="/login" />
          </Switch>
        </Router>
      </div>
    )
  }
}
const mapDispatchToProps = { logout, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth
  return { auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
