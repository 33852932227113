import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";

import "./index.css";
import { setLoader, getKYC } from "../../store/actions/Auth.js";

class KYCDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerData: [],
      isLoading: true,
      publicAddress: [],
      status: [],
      firstName: [],
      lastName: [],
      gender: [],
      dateOfBirth: [],
      documentType: [],
      idNumber: [],
      nationailty: [],
      fullAddress: [],
      session: [],
    };
  }

  async componentDidMount() {
    await this.getTransactionData();
  }

  async componentWillReceiveProps(kycData) {
    this.setState({ registerData: kycData['kycData'] });
  }

  getTransactionData = async () => {
    try {
      const waitFor = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
      this.props.setLoader({
        message: "Loading Data ...",
        status: true,
      });
      await this.props.getKYC();
      await waitFor(1200);
      this.setState({ isLoading: false });
      this.props.setLoader({ status: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");
  copiedTransaction = () => EventBus.publish("success", "Transaction Hash Copied");

  render() {
    let { registerData } = this.state;

    return (
      <div className="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">
        <h2>All approved customers data</h2>

        <div className="head">
          <div className="search-wrap">
            <input type="search" placeholder="Search" />
            <button>Search</button>
          </div>

          <div className="dropdown">
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="#/home">Default</a>
              <a className="dropdown-item" href="#/home">First name</a>
              <a className="dropdown-item" href="#/home">last Name</a>
              <a className="dropdown-item" href="#/home">Wallet Address</a>
              <a className="dropdown-item" href="#/home">Gender</a>
              <a className="dropdown-item" href="#/home">Date of birth</a>
              <a className="dropdown-item" href="#/home">Document Type</a>
              <a className="dropdown-item" href="#/home">Document ID</a>
              <a className="dropdown-item" href="#/home">Nationality</a>
              <a className="dropdown-item" href="#/home">Address</a>
              <a className="dropdown-item" href="#/home">Status</a>
            </div>
          </div>
        </div>

        <div className="customer-table">
          <table cellpadding="0" cellspacing="0">
            <thead>
              <tr>
                <th id="status">Status</th>
                <th id="publicAddress">Wallet Address</th>
                <th id="firstName">First name</th>
                <th id="lastName">Last Name</th>
                <th id="gender">Gender</th>
                <th id="dateOfBirth">Date of birth</th>
                <th id="documentType">Document Type</th>
                <th id="idNumber">Document ID</th>
                <th id="nationailty">Nationality</th>
                <th id="fullAddress">Address</th>
              </tr>
            </thead>
            <tbody>
              {registerData.map((item) =>
                <tr>

                  <td>{item['status'] ? item['status'] : "-"}</td>
                  <td>{item['publicAddress'] ? item['publicAddress'] : "-"}</td>
                  <td>{item['firstName'] ? item['firstName'] : "-"}</td>
                  <td>{item['lastName'] ? item['lastName'] : "-"}</td>
                  <td>{item['gender'] ? item['gender'] : "-"}</td>
                  <td>{item['dateOfBirth'] ? item['dateOfBirth'] : "-"}</td>
                  <td>{item['documentType'] ? item['documentType'] : "-"}</td>
                  <td>{item['idNumber'] ? item['idNumber'] : "-"}</td>
                  <td>{item['nationailty'] ? item['nationailty'] : "-"}</td>
                  <td>{item['fullAddress'] ? item['fullAddress'] : "-"}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  getKYC
};

const mapStateToProps = ({ Auth }) => {
  let { kycData } = Auth;
  return { kycData };
};
export default connect(mapStateToProps, mapDispatchToProps)(KYCDetails);
