import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import {setToken} from "../axios";
import { setLoginData, setAddress, setKYC, setGKyc, toggleStatusModal, setLoader, setKYB, setGKyb } from '../actions/Auth';

/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/v3/loginAdmin', payload });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    console.log("*** response",response["data"]["body"]["token"]);
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    console.log("*** decoded",decoded);
    yield put(setLoginData(response['data']['body']["token"]));
    yield put(setAddress(payload));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message'])
    // setTimeout(() => history.push('/home'), 1000);
  }
};


/*========== KYB FUNCTIONS =============*/

function* getKYB() {
  const { error, response } = yield call(getCall, '/v3/getPendingKyb');
  if (error){ 
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({status:false}));
    yield put(setKYB(response['data']['body']));
  }
};

function* kybGlobal() {
  const { error, response } = yield call(getCall, '/v3/kybGlobal');
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setGKyb(response['data']['body']));
    yield put(setLoader({status:false}));
  }
};

export function* updateKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v3/kybUpdate",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYB" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* setApprovedKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v3/setApprovedKYB",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyb(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v3/deleteKYB",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyb(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}


function* actionWatcher() {
  yield takeEvery('LOGIN', login);

  yield takeEvery('GET_KYB', getKYB);
  yield takeEvery('KYB_GLOBAL', kybGlobal);
  yield takeEvery('UPDATE_KYB', updateKYB);
  yield takeEvery('SET_APPROVED_KYB', setApprovedKYB);
  yield takeEvery('DELETE_KYB', deleteKYB);

};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
