import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import * as ethUtil from "ethereumjs-util";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";

import { web3 } from "../../store/web3";
import logo from '../../assets/img/logoWhite.svg';
import XDC from '../../assets/img/xdc.svg';
import { login } from "../../store/actions/Auth";
import { networkId, message } from "../../store/config";
import { Token, AdminWhitelist } from "../../store/contract/index";

import "./index.css";
import { Fragment } from "react";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      netId: "",
      address: "",
    };
  }

  async componentDidMount() {
    web3.eth.net.getId((err, netId) => {
      this.setState({ netId });
    });
    this.checkAddresses();
  }

  checkAddresses = async () => {
    if (typeof window.ethereum === 'undefined') {
      EventBus.publish('error', `Please Install Metamask!!!`);
      return;
    }

    web3.eth.net.getId(async (err, netId) => {
      if (netId !== 51) {
        const isNetworkAdded = await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x33', // Chain ID of XDC Testnet
              chainName: 'XDC Apothem',
              rpcUrls: ['https://erpc.apothem.network', 'https://earpc.apothem.network'],
              nativeCurrency: {
                name: 'TXDC',
                symbol: 'TXDC',
                decimals: 18,
              },
              blockExplorerUrls: ['https://apothem.blocksscan.io/'],
            },
          ],
        });

        // Auto-connect if the network is added
        if (isNetworkAdded) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x33' }],
          });
        }
      }
      let x = await web3.currentProvider.chainId;
      if (!x) {
        EventBus.publish('error', `Please Install Metamask!!!`);
        return;
      }
    });

    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
  };

  handleLogin = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install Metamask");
      return;
    }

    let { address } = this.state;
    if (!address) return EventBus.publish("error", "Wallet Address Not Found");

    const nonce = Math.floor(Math.random() * 10000000000);
    const signature = await web3.eth.personal.sign(
      web3.utils.utf8ToHex(`Super Admin Green Cross Donations ${nonce}`),
      address
    );

    const msg = new Buffer(`Super Admin Green Cross Donations ${nonce}`);
    const msgBuffer = ethUtil.toBuffer(msg);
    const msgHash = ethUtil.hashPersonalMessage(msgBuffer);
    const signatureBuffer = ethUtil.toBuffer(signature);
    const signatureParams = ethUtil.fromRpcSig(signatureBuffer);
    const publicKey = ethUtil.ecrecover(
      msgHash,
      signatureParams.v,
      signatureParams.r,
      signatureParams.s
    );
    const addressBuffer = ethUtil.publicToAddress(publicKey);
    const adminAddress = ethUtil.bufferToHex(addressBuffer);
    console.log("**************** adminAddress", adminAddress);

    let isSuperAdmin = await AdminWhitelist.methods
      .isSuperAdmin(adminAddress)
      .call({ from: adminAddress });
    console.log("**************** isSuperAdmin", isSuperAdmin);

    if (isSuperAdmin) {
      this.props.login({ publicAddress: address });
      setTimeout(() => window.location.reload(), 1000);
    }
    else EventBus.publish("error", "Please login through super admin address");

    // if (address) {
    //   this.props.login(address);
    //   setTimeout(() => this.props.history.push('/home'), 1000);
    // }
    // else EventBus.publish("error", "Please login through super admin address");
  };

  render() {
    let { netId } = this.state;
    if (this.props.isLogin) return this.props.history.push('/home');
    return (
          <div className="login-page">
            <div className="login-area mx-auto">
              <div className="login-form">
                <h4 className="login-title">
                  {/* <img src={logo} alt="logo" className="logoLogin" /> */}
                  <img src="images/logo.svg" alt="logo" className="logoLogin" />
                  {/* Global KYC */}
                </h4>
                <h1>Welcome to Lottery Admin</h1>
                <p className="text-white">
                  Access to manage the Lottery kyc dApp
                </p>
                {netId != networkId ? (
                  <div className="login-text">
                    <p>{message}</p>
                  </div>
                ) : (
                  <Fragment>
                    <ValidatorForm
                      className="validator-form"
                      onSubmit={this.handleLogin}
                    >
                      <Button
                        type="Submit"
                        variant="contained"
                        className="text-white login-btn"
                      >
                        Login with Wallet
                        {/* <img src={XDC} alt="xdc" className='xdc' /> */}
                      </Button>
                    </ValidatorForm>
                  </Fragment>
                )}
              </div>
            </div>
            <div className='bgLoginPg'></div>
          </div>
        );
      }
}

const mapDispatchToProps = {
  login,
};

const mapStateToProps = ({ Auth }) => {
  let { isLogin } = Auth;
  return { isLogin };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
