
import KYBList from "./views/KYBList/index.js";
import ApprovedKYCList from "./views/KYCApproved/index.js";

var routes = [
  {
    layout: "/home",
    path: "/",
    component: KYBList,
    hidden: true,
  },
  {
    layout: "/home",
    path: "/KYBDetails",
    name: "KYB Details",
    component: KYBList,
    imgUrl: "/images/details.svg",
  },
  {
    layout: "/home",
    path: "/KYCApproved",
    name: "Approved KYC Details",
    component: ApprovedKYCList,
    imgUrl: "/images/details.svg",
  },
];

export default routes;